import Pages from '@/pages';

import { ROUTES } from './routes';

export const ROUTER = [
  {
    path: ROUTES.AUTH,
    component: Pages.AuthPage,
    isPrivate: false,
  },
  {
    path: ROUTES.ACCOUNTS_USER,
    component: Pages.Users,
    isPrivate: true,
  },
  {
    path: ROUTES.BILLINGS_PLAN,
    component: Pages.BillingPlan,
    isPrivate: true,
  },
  {
    path: ROUTES.BILLINGS_TRANSACTION,
    component: Pages.BillingTransaction,
    isPrivate: true,
  },
  {
    path: ROUTES.BILLINGS_PAYMENTS,
    component: Pages.Payments,
    isPrivate: true,
  },
  {
    path: ROUTES.BILLINGS_PAYMENT_DETAIL,
    component: Pages.PaymentDetail,
    isPrivate: true,
  },
  {
    path: ROUTES.BILLINGS_SUBSCRIPTION,
    component: Pages.BillingSubscription,
    isPrivate: true,
  },
  {
    path: ROUTES.BILLINGS_PLAN_PROFILE,
    component: Pages.ProxyProfile,
    isPrivate: true,
  },
];
