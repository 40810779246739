export const ROUTES = {
  AUTH: '/auth',
  AUTH_LOGIN: '/auth/login',
  AUTH_SIGNUP: '/auth/signup',
  AUTH_FORGOT_PASSWORD: '/auth/forgot-password',
  ACCOUNTS: '/accounts',
  ACCOUNTS_USER: '/accounts/users',
  BILLINGS: '/billings',
  BILLINGS_MERCHANT: '/billings/merchant',
  BILLINGS_PLAN: '/billings/plan',
  BILLINGS_PLAN_PROFILE: '/billings/plan/profile',
  BILLINGS_TRANSACTION: '/billings/transactions',
  BILLINGS_PAYMENTS: '/billings/payments',
  BILLINGS_PAYMENT_DETAIL: '/billings/payments/detail',
  BILLINGS_SUBSCRIPTION: '/billings/subscriptions',
  PROXY_MANAGER: '/proxy-manager',
};
