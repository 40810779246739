import * as AuthProto from '../algoproxy-proto/genjs/auth/v1/merchant_pb';
import serviceGrpc from './grpc';

const authServiceGrpc = (method, schema) =>
  serviceGrpc({
    method,
    schema,
    service: AuthProto.MerchantAuthService,
  });

const fetchRoles = authServiceGrpc('fetchRole', AuthProto.MerchantAuthServiceFetchRoleRequestSchema);
const fetchUsers = authServiceGrpc('fetchUser', AuthProto.MerchantAuthServiceFetchUserRequestSchema);
const updateUser = authServiceGrpc('updateUser', AuthProto.MerchantAuthServiceUpdateUserRequestSchema);
const login = authServiceGrpc('login', AuthProto.MerchantAuthServiceLoginRequestSchema);
const fetchMe = authServiceGrpc('me', AuthProto.MerchantAuthServiceMeRequestSchema);
const refreshToken = authServiceGrpc('refreshToken', AuthProto.MerchantAuthServiceRefreshTokenRequestSchema);
const forgotPassword = authServiceGrpc('forgotPassword', AuthProto.MerchantAuthServiceForgotPasswordRequestSchema);
const changePassword = authServiceGrpc('changePassword', AuthProto.MerchantAuthServiceChangePasswordRequestSchema);

const authService = {
  fetchMe,
  login,
  fetchUsers,
  updateUser,
  fetchRoles,
  refreshToken,
  forgotPassword,
  changePassword,
};

export default authService;
