// @generated by protoc-gen-es v2.2.0 with parameter "target=js"
// @generated from file proxymanager/dns/v1/merchant.proto (package proxymanager.dns.v1, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc, serviceDesc } from "@bufbuild/protobuf/codegenv1";
import { file_errmsg_v1_errormsg } from "../../../errmsg/v1/errormsg_pb";
import { file_utils_v1_utils } from "../../../utils/v1/utils_pb";
import { file_algoenum_v1_ip_type } from "../../../algoenum/v1/ip_type_pb";

/**
 * Describes the file proxymanager/dns/v1/merchant.proto.
 */
export const file_proxymanager_dns_v1_merchant = /*@__PURE__*/
  fileDesc("CiJwcm94eW1hbmFnZXIvZG5zL3YxL21lcmNoYW50LnByb3RvEhNwcm94eW1hbmFnZXIuZG5zLnYxItIBCiFNZXJjaGFudEROU1NlcnZpY2VGZXRjaEROU1JlcXVlc3QSDgoGaWRfZG5zGAEgASgJEhMKC25hbWVfc2VhcmNoGAIgASgJEhEKCWlwX3NlYXJjaBgDIAEoCRIkCgdpcF90eXBlGAQgASgOMhMuYWxnb2VudW0udjEuSVBUeXBlEh4KBXN0YXRlGAUgASgLMg8udXRpbHMudjEuU3RhdGUSLwoKcGFnaW5hdGlvbhgGIAEoCzIbLnV0aWxzLnYxLlBhZ2luYXRpb25SZXF1ZXN0IrIBCiJNZXJjaGFudEROU1NlcnZpY2VGZXRjaEROU1Jlc3BvbnNlEiYKBWVycm9yGAEgASgLMhcuZXJybXNnLnYxLkVycm9yTWVzc2FnZRIwCgpwYWdpbmF0aW9uGAIgASgLMhwudXRpbHMudjEuUGFnaW5hdGlvblJlc3BvbnNlEjIKCGxpc3RfZG5zGAMgAygLMiAucHJveHltYW5hZ2VyLmRucy52MS5NZXJjaGFudEROUyJtCgtNZXJjaGFudEROUxIOCgZpZF9kbnMYASABKAkSJAoHaXBfdHlwZRgCIAEoDjITLmFsZ29lbnVtLnYxLklQVHlwZRIMCgRuYW1lGAMgASgJEgwKBGRuczEYBCABKAkSDAoEZG5zMhgFIAEoCTKRAQoSTWVyY2hhbnRETlNTZXJ2aWNlEnsKCEZldGNoRE5TEjYucHJveHltYW5hZ2VyLmRucy52MS5NZXJjaGFudEROU1NlcnZpY2VGZXRjaEROU1JlcXVlc3QaNy5wcm94eW1hbmFnZXIuZG5zLnYxLk1lcmNoYW50RE5TU2VydmljZUZldGNoRE5TUmVzcG9uc2VCSlpIZ2l0LnRtcHJveHktaW5mcmEuY29tL2FsZ28vYWxnb3Byb3h5LXByb3RvL2dlbi9wcm94eW1hbmFnZXIvZG5zL3YxO2Ruc3YxYgZwcm90bzM", [file_errmsg_v1_errormsg, file_utils_v1_utils, file_algoenum_v1_ip_type]);

/**
 * Describes the message proxymanager.dns.v1.MerchantDNSServiceFetchDNSRequest.
 * Use `create(MerchantDNSServiceFetchDNSRequestSchema)` to create a new message.
 */
export const MerchantDNSServiceFetchDNSRequestSchema = /*@__PURE__*/
  messageDesc(file_proxymanager_dns_v1_merchant, 0);

/**
 * Describes the message proxymanager.dns.v1.MerchantDNSServiceFetchDNSResponse.
 * Use `create(MerchantDNSServiceFetchDNSResponseSchema)` to create a new message.
 */
export const MerchantDNSServiceFetchDNSResponseSchema = /*@__PURE__*/
  messageDesc(file_proxymanager_dns_v1_merchant, 1);

/**
 * Describes the message proxymanager.dns.v1.MerchantDNS.
 * Use `create(MerchantDNSSchema)` to create a new message.
 */
export const MerchantDNSSchema = /*@__PURE__*/
  messageDesc(file_proxymanager_dns_v1_merchant, 2);

/**
 * @generated from service proxymanager.dns.v1.MerchantDNSService
 */
export const MerchantDNSService = /*@__PURE__*/
  serviceDesc(file_proxymanager_dns_v1_merchant, 0);

