// @generated by protoc-gen-es v2.2.0 with parameter "target=js"
// @generated from file algoenum/v1/vn_bank.proto (package algoenum.v1, syntax proto3)
/* eslint-disable */

import { enumDesc, fileDesc, tsEnum } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file algoenum/v1/vn_bank.proto.
 */
export const file_algoenum_v1_vn_bank = /*@__PURE__*/
  fileDesc("ChlhbGdvZW51bS92MS92bl9iYW5rLnByb3RvEgthbGdvZW51bS52MSqHAQoKVk5CYW5rVHlwZRIcChhWTl9CQU5LX1RZUEVfVU5TUEVDSUZJRUQQABIYChRWTl9CQU5LX1RZUEVfVFBfQkFOSxABEhQKEFZOX0JBTktfVFlQRV9BQ0IQAhIVChFWTl9CQU5LX1RZUEVfQklEVhADEhQKEFZOX0JBTktfVFlQRV9WQ0IQBEJHWkVnaXQudG1wcm94eS1pbmZyYS5jb20vYWxnby9hbGdvcHJveHktcHJvdG8vZ2VuL2FsZ29lbnVtL3YxO2FsZ29lbnVtdjFiBnByb3RvMw");

/**
 * Describes the enum algoenum.v1.VNBankType.
 */
export const VNBankTypeSchema = /*@__PURE__*/
  enumDesc(file_algoenum_v1_vn_bank, 0);

/**
 * @generated from enum algoenum.v1.VNBankType
 */
export const VNBankType = /*@__PURE__*/
  tsEnum(VNBankTypeSchema);

