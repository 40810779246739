import { ROUTES } from '@/router/routes';

const PERMISSIONS = {
  Login: '/auth.v1.MerchantAuthService/Login',
  Me: '/auth.v1.MerchantAuthService/Me',
  FetchUser: '/auth.v1.MerchantAuthService/FetchUser',
  UpdateUser: '/auth.v1.MerchantAuthService/UpdateUser',
  FetchRole: '/auth.v1.MerchantAuthService/FetchRole',

  FetchSubscription: '/proxymanager.subscription.v1.MerchantSubscriptionService/FetchSubscription',
  FetchPlan: '/proxymanager.plan.v1.MerchantPlanService/FetchPlan',
  UpdatePlanPrice: '/billing.plan.v1.MerchantPlanService/UpdatePlanPrice',
  FetchMerchantInfo: '/billing.merchant.v1.MerchantMerchantService/FetchMerchantInfo',
  FetchUserBalance: '/billing.transaction.v1.MerchantTransactionService/FetchUserBalance',
  FetchLocation: '/proxymanager.location.v1.MerchantLocationService/FetchLocation',
  FetchTransaction: '/billing.transaction.v1.MerchantTransactionService/FetchTransaction',
  FetchPaymentGatewayType: '/billing.payment.v1.MerchantPaymentService/FetchPaymentGatewayType',
  FetchAppotaPaymentGatewayDetail: '/billing.payment.v1.MerchantPaymentService/FetchAppotaPaymentGatewayDetail',
  FetchDodoPaymentGatewayDetail: '/billing.payment.v1.MerchantPaymentService/FetchDodoPaymentGatewayDetail',

  FetchProxyProfile: '/proxymanager.proxyprofile.v1.MerchantProxyProfileService/FetchProxyProfile',
  CreateProxyProfile: '/proxymanager.proxyprofile.v1.MerchantProxyProfileService/CreateProxyProfile',
  UpdateProxyProfile: '/proxymanager.proxyprofile.v1.MerchantProxyProfileService/UpdateProxyProfile',
  ConfigProxyProfileLocation: '/proxymanager.proxyprofile.v1.MerchantProxyProfileService/ConfigProxyProfileLocation',
  ConfigProxyProfileIPAllow: '/proxymanager.proxyprofile.v1.MerchantProxyProfileService/ConfigProxyProfileIPAllow',
};

const PERMISSIONS_ACCOUNTS = {
  [ROUTES.ACCOUNTS_USER]: [PERMISSIONS.FetchUser, PERMISSIONS.UpdateUser],
};

const PERMISSION_BILLINGS = {
  [ROUTES.BILLINGS_PLAN]: [PERMISSIONS.FetchPlan, PERMISSIONS.UpdatePlanPrice],
  [ROUTES.BILLINGS_PLAN_PROFILE]: [
    PERMISSIONS.FetchProxyProfile,
    PERMISSIONS.CreateProxyProfile,
    PERMISSIONS.UpdateProxyProfile,
    PERMISSIONS.ConfigProxyProfileLocation,
    PERMISSIONS.ConfigProxyProfileIPAllow,
  ],
  [ROUTES.BILLINGS_PAYMENTS]: [PERMISSIONS.FetchPaymentGatewayType],
  [ROUTES.BILLINGS_PAYMENT_DETAIL]: [
    PERMISSIONS.FetchAppotaPaymentGatewayDetail,
    PERMISSIONS.FetchDodoPaymentGatewayDetail,
  ],
  [ROUTES.BILLINGS_TRANSACTION]: [PERMISSIONS.FetchTransaction],
  [ROUTES.BILLINGS_SUBSCRIPTION]: [PERMISSIONS.FetchSubscription],
};

const mergePermission = (permissions) => {
  return Object.values(permissions).reduce((acc, curr) => {
    acc.push(...curr);
    return acc;
  }, []);
};

const PERMISSIONS_PAGE = {
  ...PERMISSION_BILLINGS,
  [ROUTES.BILLINGS]: mergePermission(PERMISSION_BILLINGS),
  ...PERMISSIONS_ACCOUNTS,
  [ROUTES.ACCOUNTS]: mergePermission(PERMISSIONS_ACCOUNTS),
};

export { PERMISSIONS, PERMISSIONS_PAGE };
