import { create } from '@bufbuild/protobuf';
import { createClient } from '@connectrpc/connect';
import { createConnectTransport } from '@connectrpc/connect-web';

import { userAction } from '@/actions/user';
import { ErrorCode } from '@/algoproxy-proto/genjs/errmsg/v1/errormsg_pb';
import { bigInt } from '@/utils/helper/number';

let isRefreshing = false;
let failedRequests = [];

const processQueue = (success) => {
  failedRequests.forEach((prom) => {
    if (success) prom.resolve();
    else prom.reject();
  });

  failedRequests = [];
};

const logger = (next) => async (req) => {
  req.header.set('Authorization', `Bearer ${localStorage.getItem('token')}`);

  try {
    const res = await next(req);

    // Check if token is expired
    if (res?.message?.error?.code === ErrorCode['UNAUTHORIZED']) {
      const methodName = req.method?.name;
      if (methodName === 'ForgotPassword') {
        return res;
      }
      if (methodName === 'RefreshToken') {
        userAction.signOut();
        return;
      }

      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedRequests.push({ resolve, reject });
        })
          .then(() => {
            req.header.set('Authorization', `Bearer ${localStorage.getItem('token')}`);
            return next(req);
          })
          .catch(() => {
            userAction.signOut();
            return;
          });
      }

      isRefreshing = true;

      const refreshSuccess = await userAction.refreshTokenAction();

      if (refreshSuccess) {
        processQueue(true);
        isRefreshing = false;

        req.header.set('Authorization', `Bearer ${localStorage.getItem('token')}`);
        return next(req);
      } else {
        processQueue(false);
        isRefreshing = false;
        userAction.signOut();
        return;
      }
    }

    return res;
  } catch (error) {
    console.error('GRPC request error:', error);
    return { message: { error: { code: 'INTERNAL_ERROR', message: error?.message } } };
  }
};

const transport = createConnectTransport({
  // eslint-disable-next-line no-undef
  baseUrl: localStorage.getItem('api_url') || window.location.origin.replace(process.env.REACT_APP_SUBDOMAIN, 'api'),
  interceptors: [logger],
});

const clientGrpc = (service) => createClient(service, transport);

const serviceGrpc = ({ method, schema, service }) => {
  return (body) => {
    const pagination = body?.pagination;
    if (pagination) {
      body.pagination = {
        pageNumber: bigInt(pagination.pageNumber || 1),
        pageSize: bigInt(pagination.pageSize || 100),
      };
    }
    const requestGrpc = create(schema, body);
    return clientGrpc(service)[method](requestGrpc);
  };
};

export default serviceGrpc;
