import * as AuthBackOfficeProto from '../algoproxy-proto/genjs/auth/v1/backoffice_auth_pb';
import * as BillingMerchantBackOfficeProto from '../algoproxy-proto/genjs/billing/merchant/v1/backoffice_pb';
import { client } from './axios';
import serviceGrpc from './grpc';

const login = (body) => client.post(`/control-plane/auth/login`, body);
const fetchMe = () => client.post(`/control-plane/auth/me`, {});
const addUser = (body) => client.post(`/user/add`, body);
const fetchRoles = (body) => client.post(`/control-plane/auth/fetch-role`, body);
const createRole = (body) => client.post(`/control-plane/auth/create-role`, body);
const updateRole = (body) => client.post(`/control-plane/auth/update-role`, body);
const fetchServices = (body) => client.post(`/control-plane/auth/fetch-service`, body);
const createService = (body) => client.post(`/control-plane/auth/create-service`, body);
const updateService = (body) => client.post(`/control-plane/auth/update-service`, body);
const fetchPaths = (body) => client.post(`/control-plane/auth/fetch-path`, body);
const createPath = (body) => client.post(`/control-plane/auth/create-path`, body);
const updatePath = (body) => client.post(`/control-plane/auth/update-path`, body);
const fetchPolicies = (body) => client.post(`/control-plane/auth/fetch-policy`, body);
const createPolicy = (body) => client.post(`/control-plane/auth/create-policy`, body);
const updatePolicy = (body) => client.post(`/control-plane/auth/update-policy`, body);
const fetchUsers = (body) => client.post(`/control-plane/auth/fetch-user`, body);
const createUser = serviceGrpc({
  method: 'createUser',
  schema: AuthBackOfficeProto.BackofficeAuthServiceCreateUserRequestSchema,
  service: AuthBackOfficeProto.BackofficeAuthService,
});
const updateUser = serviceGrpc({
  method: 'updateUser',
  schema: AuthBackOfficeProto.BackofficeAuthServiceUpdateUserRequestSchema,
  service: AuthBackOfficeProto.BackofficeAuthService,
});
const fetchProxies = (params) => client.get(`/proxy-order/list`, { params });
const fetchDashboard = (params) => client.get(`/dashboard`, { params });
const fetchRevenue = (params) => client.get(`/dashboard/deposit`, { params });
const fetchProducts = (body) => client.post(`/merchant.v1.MerchantService/ListProductBase`, body);
const createProduct = (body) => client.post(`/merchant.v1.MerchantService/CreateProductBase`, body);
const updateProduct = (body) => client.post(`/merchant.v1.MerchantService/UpdateProductBase`, body);
const fetchMerchants = serviceGrpc({
  method: 'fetchMerchant',
  schema: BillingMerchantBackOfficeProto.BackofficeMerchantServiceFetchMerchantRequestSchema,
  service: BillingMerchantBackOfficeProto.BackofficeMerchantService,
});
const addMerchant = serviceGrpc({
  method: 'createMerchant',
  schema: BillingMerchantBackOfficeProto.BackofficeMerchantServiceCreateMerchantRequestSchema,
  service: BillingMerchantBackOfficeProto.BackofficeMerchantService,
});
const updateMerchant = serviceGrpc({
  method: 'updateMerchant',
  schema: BillingMerchantBackOfficeProto.BackofficeMerchantServiceUpdateMerchantRequestSchema,
  service: BillingMerchantBackOfficeProto.BackofficeMerchantService,
});
const fetchMerchantAdmins = (body) => client.post(`/merchant.v1.MerchantService/ListMerchantUser`, body);
const addMerchantAdmin = (body) => client.post(`/merchant.v1.MerchantService/AddMerchantAdmin`, body);
const updateMerchantAdmin = (body) => client.post(`/merchant.v1.MerchantService/UpdateUser`, body);

const algoService = {
  updateMerchantAdmin,
  fetchMerchants,
  addMerchant,
  updateMerchant,
  fetchMerchantAdmins,
  addMerchantAdmin,
  fetchProducts,
  createProduct,
  updateProduct,
  fetchUsers,
  createUser,
  updateUser,
  fetchPaths,
  createPath,
  updatePath,
  fetchPolicies,
  createPolicy,
  updatePolicy,
  fetchServices,
  createService,
  updateService,
  createRole,
  updateRole,
  fetchMe,
  login,
  addUser,
  fetchRoles,
  fetchProxies,
  fetchDashboard,
  fetchRevenue,
};

export default algoService;
