import { Redirect, Route, Switch } from 'react-router-dom';

import { useCallback, useEffect, useState } from 'react';

import './App.scss';
import PrivateRoute from './PrivateRoute';
import { userAction } from './actions/user';
import LoadingIndicator from './components/LoadingIndicator';
import ScrollToTop from './components/ScrollToTop';
import { ROUTER } from './router';
import { ROUTES } from './router/routes';
import { COOKIE_NAME } from './utils/constants';

function App() {
  const [isReadyUser, setIsReadyUser] = useState(false);

  const loadUser = useCallback(async () => {
    try {
      const profileJson = localStorage.getItem(COOKIE_NAME);
      if (profileJson) {
        const profile = JSON.parse(profileJson);
        await userAction.signIn(profile);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsReadyUser(true);
    }
  }, []);

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  if (!isReadyUser)
    return (
      <div className='App'>
        <LoadingIndicator />
      </div>
    );

  const renderRoutes = () => {
    return ROUTER.map((route) => {
      if (route.isPrivate) {
        return <PrivateRoute key={route.path} path={route.path} component={route.component} exact />;
      }
      return <Route key={route.path} path={route.path} component={route.component} />;
    });
  };

  return (
    <div className='App'>
      <ScrollToTop />
      <Switch>
        {renderRoutes()}
        <Route path=''>
          <Redirect to={ROUTES.BILLINGS_PLAN} />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
