import * as PaymentProto from '../algoproxy-proto/genjs/billing/payment/v1/merchant_pb';
import * as PlanPriceProto from '../algoproxy-proto/genjs/billing/planprice/v1/merchant_pb';
import * as TransactionProto from '../algoproxy-proto/genjs/billing/transaction/v1/merchant_pb';
import * as ManagerDNSProto from '../algoproxy-proto/genjs/proxymanager/dns/v1/merchant_pb';
import * as ManagerLocationProto from '../algoproxy-proto/genjs/proxymanager/location/v1/merchant_pb';
import * as PlanProto from '../algoproxy-proto/genjs/proxymanager/plan/v1/merchant_pb';
import * as ProxyProfileProto from '../algoproxy-proto/genjs/proxymanager/proxyprofile/v1/merchant_pb';
import * as SubscriptionProto from '../algoproxy-proto/genjs/proxymanager/subscription/v1/merchant_pb';
import serviceGrpc from './grpc';

const fetchSubscription = serviceGrpc({
  method: 'fetchSubscription',
  schema: SubscriptionProto.MerchantSubscriptionServiceFetchSubscriptionRequestSchema,
  service: SubscriptionProto.MerchantSubscriptionService,
});
const fetchPlan = serviceGrpc({
  method: 'fetchPlan',
  schema: PlanProto.MerchantPlanServiceFetchPlanRequestSchema,
  service: PlanProto.MerchantPlanService,
});

const fetchPlanPrice = serviceGrpc({
  method: 'fetchPlanPrice',
  schema: PlanPriceProto.MerchantPlanPriceServiceFetchPlanPriceRequestSchema,
  service: PlanPriceProto.MerchantPlanPriceService,
});
const updatePlanPrice = serviceGrpc({
  method: 'updatePlanPrice',
  schema: PlanPriceProto.MerchantPlanPriceServiceUpdatePlanPriceRequestSchema,
  service: PlanPriceProto.MerchantPlanPriceService,
});
const fetchLocation = serviceGrpc({
  method: 'fetchLocation',
  schema: ManagerLocationProto.MerchantLocationServiceFetchLocationRequestSchema,
  service: ManagerLocationProto.MerchantLocationService,
});
const fetchUserBalance = serviceGrpc({
  method: 'fetchUserBalance',
  schema: TransactionProto.MerchantTransactionServiceFetchUserBalanceRequestSchema,
  service: TransactionProto.MerchantTransactionService,
});
const fetchTransaction = serviceGrpc({
  method: 'fetchTransaction',
  schema: TransactionProto.MerchantTransactionServiceFetchTransactionRequestSchema,
  service: TransactionProto.MerchantTransactionService,
});

// PAYMENT
const fetchPaymentGatewayType = serviceGrpc({
  method: 'fetchPaymentGatewayType',
  schema: PaymentProto.MerchantPaymentServiceFetchPaymentGatewayTypeRequestSchema,
  service: PaymentProto.MerchantPaymentService,
});
const fetchPaymentGateway = serviceGrpc({
  method: 'fetchPaymentGateway',
  schema: PaymentProto.MerchantPaymentServiceFetchPaymentGatewayRequestSchema,
  service: PaymentProto.MerchantPaymentService,
});
const fetchAppotaPaymentGatewayDetail = serviceGrpc({
  method: 'fetchAppotaPaymentGatewayDetail',
  schema: PaymentProto.MerchantPaymentServiceFetchAppotaPaymentGatewayDetailRequestSchema,
  service: PaymentProto.MerchantPaymentService,
});
const updatePaymentGatewayState = serviceGrpc({
  method: 'updatePaymentGatewayState',
  schema: PaymentProto.MerchantPaymentServiceUpdatePaymentGatewayStateRequestSchema,
  service: PaymentProto.MerchantPaymentService,
});
const createAppotaPaymentGateway = serviceGrpc({
  method: 'createAppotaPaymentGateway',
  schema: PaymentProto.MerchantPaymentServiceCreateAppotaPaymentGatewayRequestSchema,
  service: PaymentProto.MerchantPaymentService,
});
const createDodoPaymentGateway = serviceGrpc({
  method: 'createDodoPaymentGateway',
  schema: PaymentProto.MerchantPaymentServiceCreateDodoPaymentGatewayRequestSchema,
  service: PaymentProto.MerchantPaymentService,
});
const fetchDodoPaymentGatewayDetail = serviceGrpc({
  method: 'fetchDodoPaymentGatewayDetail',
  schema: PaymentProto.MerchantPaymentServiceFetchDodoPaymentGatewayDetailRequestSchema,
  service: PaymentProto.MerchantPaymentService,
});
const fetchAppotaAmountTopUp = serviceGrpc({
  method: 'fetchAppotaAmountTopUp',
  schema: PaymentProto.MerchantPaymentServiceFetchAppotaAmountTopUpRequestSchema,
  service: PaymentProto.MerchantPaymentService,
});
const createAppotaAmountTopUp = serviceGrpc({
  method: 'createAppotaAmountTopUp',
  schema: PaymentProto.MerchantPaymentServiceCreateAppotaAmountTopUpRequestSchema,
  service: PaymentProto.MerchantPaymentService,
});
const fetchDodoAmountTopUp = serviceGrpc({
  method: 'fetchDodoAmountTopUp',
  schema: PaymentProto.MerchantPaymentServiceFetchDodoAmountTopUpRequestSchema,
  service: PaymentProto.MerchantPaymentService,
});
const createDodoAmountTopUp = serviceGrpc({
  method: 'createDodoAmountTopUp',
  schema: PaymentProto.MerchantPaymentServiceCreateDodoAmountTopUpRequestSchema,
  service: PaymentProto.MerchantPaymentService,
});
const fetchSePayPaymentGatewayDetail = serviceGrpc({
  method: 'fetchSePayPaymentGatewayDetail',
  schema: PaymentProto.MerchantPaymentServiceFetchSePayPaymentGatewayDetailRequestSchema,
  service: PaymentProto.MerchantPaymentService,
});
const createSePayPaymentGateway = serviceGrpc({
  method: 'createSePayPaymentGateway',
  schema: PaymentProto.MerchantPaymentServiceCreateSePayPaymentGatewayRequestSchema,
  service: PaymentProto.MerchantPaymentService,
});
const fetchSePayAmountTopUp = serviceGrpc({
  method: 'fetchSePayAmountTopUp',
  schema: PaymentProto.MerchantPaymentServiceFetchSePayAmountTopUpRequestSchema,
  service: PaymentProto.MerchantPaymentService,
});
const createSePayAmountTopUp = serviceGrpc({
  method: 'createSePayAmountTopUp',
  schema: PaymentProto.MerchantPaymentServiceCreateSePayAmountTopUpRequestSchema,
  service: PaymentProto.MerchantPaymentService,
});

const fetchProxyProfile = serviceGrpc({
  method: 'fetchProxyProfile',
  schema: ProxyProfileProto.MerchantProxyProfileServiceFetchProxyProfileRequestSchema,
  service: ProxyProfileProto.MerchantProxyProfileService,
});
const createProxyProfile = serviceGrpc({
  method: 'createProxyProfile',
  schema: ProxyProfileProto.MerchantProxyProfileServiceCreateProxyProfileRequestSchema,
  service: ProxyProfileProto.MerchantProxyProfileService,
});
const updateProxyProfile = serviceGrpc({
  method: 'updateProxyProfile',
  schema: ProxyProfileProto.MerchantProxyProfileServiceUpdateProxyProfileRequestSchema,
  service: ProxyProfileProto.MerchantProxyProfileService,
});
const configProxyProfileLocation = serviceGrpc({
  method: 'configProxyProfileLocation',
  schema: ProxyProfileProto.MerchantProxyProfileServiceConfigProxyProfileLocationRequestSchema,
  service: ProxyProfileProto.MerchantProxyProfileService,
});
const configProxyProfileIPAllow = serviceGrpc({
  method: 'configProxyProfileIPAllow',
  schema: ProxyProfileProto.MerchantProxyProfileServiceConfigProxyProfileIPAllowRequestSchema,
  service: ProxyProfileProto.MerchantProxyProfileService,
});
const setDefaultProxyProfile = serviceGrpc({
  method: 'setDefaultProxyProfile',
  schema: ProxyProfileProto.MerchantProxyProfileServiceSetDefaultProxyProfileRequestSchema,
  service: ProxyProfileProto.MerchantProxyProfileService,
});

// DNS
const fetchDNS = serviceGrpc({
  method: 'fetchDNS',
  schema: ManagerDNSProto.MerchantDNSServiceFetchDNSRequestSchema,
  service: ManagerDNSProto.MerchantDNSService,
});

const merchantService = {
  fetchSubscription,
  fetchPlan,
  fetchPlanPrice,
  updatePlanPrice,
  fetchLocation,
  fetchUserBalance,
  fetchTransaction,
  fetchPaymentGatewayType,
  fetchPaymentGateway,
  fetchAppotaPaymentGatewayDetail,
  updatePaymentGatewayState,
  createAppotaPaymentGateway,
  createDodoPaymentGateway,
  fetchDodoPaymentGatewayDetail,
  fetchAppotaAmountTopUp,
  createAppotaAmountTopUp,
  fetchDodoAmountTopUp,
  createDodoAmountTopUp,
  fetchSePayPaymentGatewayDetail,
  createSePayPaymentGateway,
  fetchSePayAmountTopUp,
  createSePayAmountTopUp,
  fetchProxyProfile,
  createProxyProfile,
  updateProxyProfile,
  configProxyProfileLocation,
  configProxyProfileIPAllow,
  setDefaultProxyProfile,
  fetchDNS,
};

export default merchantService;
