// @generated by protoc-gen-es v2.2.0 with parameter "target=js"
// @generated from file billing/planprice/v1/merchant.proto (package billing.planprice.v1, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc, serviceDesc } from "@bufbuild/protobuf/codegenv1";
import { file_algoenum_v1_currency } from "../../../algoenum/v1/currency_pb";
import { file_errmsg_v1_errormsg } from "../../../errmsg/v1/errormsg_pb";

/**
 * Describes the file billing/planprice/v1/merchant.proto.
 */
export const file_billing_planprice_v1_merchant = /*@__PURE__*/
  fileDesc("CiNiaWxsaW5nL3BsYW5wcmljZS92MS9tZXJjaGFudC5wcm90bxIUYmlsbGluZy5wbGFucHJpY2UudjEiQAotTWVyY2hhbnRQbGFuUHJpY2VTZXJ2aWNlRmV0Y2hQbGFuUHJpY2VSZXF1ZXN0Eg8KB2lkX3BsYW4YASABKAkirAEKLk1lcmNoYW50UGxhblByaWNlU2VydmljZUZldGNoUGxhblByaWNlUmVzcG9uc2USJgoFZXJyb3IYASABKAsyFy5lcnJtc2cudjEuRXJyb3JNZXNzYWdlElIKC3BsYW5fcHJpY2VzGAIgAygLMj0uYmlsbGluZy5wbGFucHJpY2UudjEuTWVyY2hhbnRQbGFuUHJpY2VTZXJ2aWNlUGxhblByaWNlRW50aXR5Il8KLk1lcmNoYW50UGxhblByaWNlU2VydmljZVVwZGF0ZVBsYW5QcmljZVJlcXVlc3QSFQoNaWRfcGxhbl9wcmljZRgBIAEoCRIWCg5wdXJjaGFzZV9wcmljZRgCIAEoASJZCi9NZXJjaGFudFBsYW5QcmljZVNlcnZpY2VVcGRhdGVQbGFuUHJpY2VSZXNwb25zZRImCgVlcnJvchgBIAEoCzIXLmVycm1zZy52MS5FcnJvck1lc3NhZ2Ui0wEKJ01lcmNoYW50UGxhblByaWNlU2VydmljZVBsYW5QcmljZUVudGl0eRIVCg1pZF9wbGFuX3ByaWNlGAEgASgJEhwKFGJpbGxpbmdfY3ljbGVfaW5fc2VjGAIgASgDEh4KFmRhdGFfdHJhbnNmZXJfaW5fZ2J5dGUYAyABKAESEgoKY29zdF9wcmljZRgEIAEoARIWCg5wdXJjaGFzZV9wcmljZRgFIAEoARInCghjdXJyZW5jeRgGIAEoDjIVLmFsZ29lbnVtLnYxLkN1cnJlbmN5MtkCChhNZXJjaGFudFBsYW5QcmljZVNlcnZpY2USmwEKDkZldGNoUGxhblByaWNlEkMuYmlsbGluZy5wbGFucHJpY2UudjEuTWVyY2hhbnRQbGFuUHJpY2VTZXJ2aWNlRmV0Y2hQbGFuUHJpY2VSZXF1ZXN0GkQuYmlsbGluZy5wbGFucHJpY2UudjEuTWVyY2hhbnRQbGFuUHJpY2VTZXJ2aWNlRmV0Y2hQbGFuUHJpY2VSZXNwb25zZRKeAQoPVXBkYXRlUGxhblByaWNlEkQuYmlsbGluZy5wbGFucHJpY2UudjEuTWVyY2hhbnRQbGFuUHJpY2VTZXJ2aWNlVXBkYXRlUGxhblByaWNlUmVxdWVzdBpFLmJpbGxpbmcucGxhbnByaWNlLnYxLk1lcmNoYW50UGxhblByaWNlU2VydmljZVVwZGF0ZVBsYW5QcmljZVJlc3BvbnNlQlFaT2dpdC50bXByb3h5LWluZnJhLmNvbS9hbGdvL2FsZ29wcm94eS1wcm90by9nZW4vYmlsbGluZy9wbGFucHJpY2UvdjE7cGxhbnByaWNldjFiBnByb3RvMw", [file_algoenum_v1_currency, file_errmsg_v1_errormsg]);

/**
 * Describes the message billing.planprice.v1.MerchantPlanPriceServiceFetchPlanPriceRequest.
 * Use `create(MerchantPlanPriceServiceFetchPlanPriceRequestSchema)` to create a new message.
 */
export const MerchantPlanPriceServiceFetchPlanPriceRequestSchema = /*@__PURE__*/
  messageDesc(file_billing_planprice_v1_merchant, 0);

/**
 * Describes the message billing.planprice.v1.MerchantPlanPriceServiceFetchPlanPriceResponse.
 * Use `create(MerchantPlanPriceServiceFetchPlanPriceResponseSchema)` to create a new message.
 */
export const MerchantPlanPriceServiceFetchPlanPriceResponseSchema = /*@__PURE__*/
  messageDesc(file_billing_planprice_v1_merchant, 1);

/**
 * Describes the message billing.planprice.v1.MerchantPlanPriceServiceUpdatePlanPriceRequest.
 * Use `create(MerchantPlanPriceServiceUpdatePlanPriceRequestSchema)` to create a new message.
 */
export const MerchantPlanPriceServiceUpdatePlanPriceRequestSchema = /*@__PURE__*/
  messageDesc(file_billing_planprice_v1_merchant, 2);

/**
 * Describes the message billing.planprice.v1.MerchantPlanPriceServiceUpdatePlanPriceResponse.
 * Use `create(MerchantPlanPriceServiceUpdatePlanPriceResponseSchema)` to create a new message.
 */
export const MerchantPlanPriceServiceUpdatePlanPriceResponseSchema = /*@__PURE__*/
  messageDesc(file_billing_planprice_v1_merchant, 3);

/**
 * Describes the message billing.planprice.v1.MerchantPlanPriceServicePlanPriceEntity.
 * Use `create(MerchantPlanPriceServicePlanPriceEntitySchema)` to create a new message.
 */
export const MerchantPlanPriceServicePlanPriceEntitySchema = /*@__PURE__*/
  messageDesc(file_billing_planprice_v1_merchant, 4);

/**
 * @generated from service billing.planprice.v1.MerchantPlanPriceService
 */
export const MerchantPlanPriceService = /*@__PURE__*/
  serviceDesc(file_billing_planprice_v1_merchant, 0);

