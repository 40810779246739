import { formatNumber } from '..';

export const capitalizeFirstLetter = (str) => str?.charAt(0)?.toUpperCase() + str?.slice(1);

export const generateDeviceIdFromUserAgent = () => {
  const storageKey = 'user_device_id';
  let deviceId = localStorage.getItem(storageKey);

  if (!deviceId) {
    const userAgent = navigator.userAgent;
    const platform = navigator.platform;
    const screenResolution = `${window.screen.width}x${window.screen.height}`;
    const colorDepth = window.screen.colorDepth;
    // eslint-disable-next-line no-undef
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const deviceInfo = [userAgent, platform, screenResolution, colorDepth, timezone].join('|');
    deviceId = hashString(deviceInfo);
    localStorage.setItem(storageKey, deviceId);
  }

  return deviceId;
};

const hashString = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }
  return Math.abs(hash).toString(16);
};

export const convertSecondsToDays = (seconds) => {
  const day = formatNumber(Number(seconds) / 86400);
  if (+day < 1) {
    const hour = formatNumber(Number(seconds) / 3600);
    return `${hour} ${+hour >= 2 ? 'hours' : 'hour'}`;
  }
  return `${day} ${+day >= 2 ? 'days' : 'day'}`;
};
