import ForgotPasswordPage from './Auth/ForgotPassword/Loadable';
import AuthPage from './Auth/Loadable';
import LoginPage from './Auth/Login/Loadable';
import BillingMerchant from './Billing/MerchantConfig/Loadable';
import PaymentDetail from './Billing/Payments/Detail/Loadable';
import Payments from './Billing/Payments/Loadable';
import BillingPlan from './Billing/Plan/Loadable';
import BillingSubscription from './Billing/Subscription/Loadable';
import BillingTransaction from './Billing/Transaction/Loadable';
import Users from './ManageAccount/Users/Loadable';
import ProxyProfile from './ProxyManager/ProxyProfile/Loadable';

const pages = {
  AuthPage,
  LoginPage,
  ForgotPasswordPage,
  Users,
  BillingMerchant,
  Payments,
  BillingPlan,
  BillingTransaction,
  BillingSubscription,
  PaymentDetail,
  ProxyProfile,
};

export default pages;
