// @generated by protoc-gen-es v2.2.0 with parameter "target=js"
// @generated from file proxymanager/location/v1/merchant.proto (package proxymanager.location.v1, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc, serviceDesc } from "@bufbuild/protobuf/codegenv1";
import { file_utils_v1_utils } from "../../../utils/v1/utils_pb";
import { file_errmsg_v1_errormsg } from "../../../errmsg/v1/errormsg_pb";
import { file_algoenum_v1_location_level } from "../../../algoenum/v1/location_level_pb";

/**
 * Describes the file proxymanager/location/v1/merchant.proto.
 */
export const file_proxymanager_location_v1_merchant = /*@__PURE__*/
  fileDesc("Cidwcm94eW1hbmFnZXIvbG9jYXRpb24vdjEvbWVyY2hhbnQucHJvdG8SGHByb3h5bWFuYWdlci5sb2NhdGlvbi52MSKHAgorTWVyY2hhbnRMb2NhdGlvblNlcnZpY2VGZXRjaExvY2F0aW9uUmVxdWVzdBITCgtpZF9sb2NhdGlvbhgBIAEoCRIUCgxpZF9sb2NhdGlvbnMYAiADKAkSGgoSaWRfcGFyZW50X2xvY2F0aW9uGAMgASgJEjIKDmxvY2F0aW9uX2xldmVsGAQgASgOMhouYWxnb2VudW0udjEuTG9jYXRpb25MZXZlbBITCgtuYW1lX3NlYXJjaBgFIAEoCRIXCg9pc29fY29kZV9zZWFyY2gYBiABKAkSLwoKcGFnaW5hdGlvbhgHIAEoCzIbLnV0aWxzLnYxLlBhZ2luYXRpb25SZXF1ZXN0IswBCixNZXJjaGFudExvY2F0aW9uU2VydmljZUZldGNoTG9jYXRpb25SZXNwb25zZRImCgVlcnJvchgBIAEoCzIXLmVycm1zZy52MS5FcnJvck1lc3NhZ2USMAoKcGFnaW5hdGlvbhgCIAEoCzIcLnV0aWxzLnYxLlBhZ2luYXRpb25SZXNwb25zZRJCCglsb2NhdGlvbnMYAyADKAsyLy5wcm94eW1hbmFnZXIubG9jYXRpb24udjEuTWVyY2hhbnRMb2NhdGlvbk1vZGVsItABChVNZXJjaGFudExvY2F0aW9uTW9kZWwSEwoLaWRfbG9jYXRpb24YASABKAkSPwoGcGFyZW50GAIgASgLMi8ucHJveHltYW5hZ2VyLmxvY2F0aW9uLnYxLk1lcmNoYW50TG9jYXRpb25Nb2RlbBIyCg5sb2NhdGlvbl9sZXZlbBgDIAEoDjIaLmFsZ29lbnVtLnYxLkxvY2F0aW9uTGV2ZWwSDAoEbmFtZRgEIAEoCRIQCghpc29fY29kZRgFIAEoCRINCgVlbW9qaRgGIAEoCTK6AQoXTWVyY2hhbnRMb2NhdGlvblNlcnZpY2USngEKDUZldGNoTG9jYXRpb24SRS5wcm94eW1hbmFnZXIubG9jYXRpb24udjEuTWVyY2hhbnRMb2NhdGlvblNlcnZpY2VGZXRjaExvY2F0aW9uUmVxdWVzdBpGLnByb3h5bWFuYWdlci5sb2NhdGlvbi52MS5NZXJjaGFudExvY2F0aW9uU2VydmljZUZldGNoTG9jYXRpb25SZXNwb25zZUJUWlJnaXQudG1wcm94eS1pbmZyYS5jb20vYWxnby9hbGdvcHJveHktcHJvdG8vZ2VuL3Byb3h5bWFuYWdlci9sb2NhdGlvbi92MTtsb2NhdGlvbnYxYgZwcm90bzM", [file_utils_v1_utils, file_errmsg_v1_errormsg, file_algoenum_v1_location_level]);

/**
 * Describes the message proxymanager.location.v1.MerchantLocationServiceFetchLocationRequest.
 * Use `create(MerchantLocationServiceFetchLocationRequestSchema)` to create a new message.
 */
export const MerchantLocationServiceFetchLocationRequestSchema = /*@__PURE__*/
  messageDesc(file_proxymanager_location_v1_merchant, 0);

/**
 * Describes the message proxymanager.location.v1.MerchantLocationServiceFetchLocationResponse.
 * Use `create(MerchantLocationServiceFetchLocationResponseSchema)` to create a new message.
 */
export const MerchantLocationServiceFetchLocationResponseSchema = /*@__PURE__*/
  messageDesc(file_proxymanager_location_v1_merchant, 1);

/**
 * Describes the message proxymanager.location.v1.MerchantLocationModel.
 * Use `create(MerchantLocationModelSchema)` to create a new message.
 */
export const MerchantLocationModelSchema = /*@__PURE__*/
  messageDesc(file_proxymanager_location_v1_merchant, 2);

/**
 * @generated from service proxymanager.location.v1.MerchantLocationService
 */
export const MerchantLocationService = /*@__PURE__*/
  serviceDesc(file_proxymanager_location_v1_merchant, 0);

